import React, { MouseEventHandler, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './Header.css';
import { StringUtils } from '../../../utils/StringUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { DividerIcon } from '../../atoms/Icons/Styleguide/DividerIcon';
import { ArkadiumIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/ArkadiumLogo';
import { getHeaderMenuLinks } from '../../constants/HeaderMenuLinks';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { getHeaderTabButtons } from '../../constants/HeaderTabButtons';
import { PageTypes } from '../../constants/Pages';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { AvatarGemsCounter } from '../../molecules/AvatarGemsCounter/AvatarGemsCounter';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { setSideMenuActivePage, setSignInUpState } from '../../store/ducks/layout';
import {
  GemsAnalyticsRegistrationLocations,
  setSocialRegistrationLocation
} from '../../store/ducks/leanplum/lpAnalytics';

//new header with abtest
const Header = React.memo((props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const activeUserSubscriptions = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions);
  const currentLang = useSelector(({ currentLang }) => currentLang);
  const currentPageType = useSelector(({ pageType }) => pageType);
  const currentCategoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const categoryPromoData = useSelector(({ categoryPromoData }) => categoryPromoData);
  const pageType = useSelector(({ pageType }) => pageType);
  const hideHeader: boolean = pageType === PageTypes.Game;
  const menuLinks = getHeaderMenuLinks(t, currentLang);
  const tabButtons: TabButtonProps[] = getHeaderTabButtons(dispatch, currentLang);
  const categoryPromoMenu = {
    title: categoryPromoData?.categoryName,
    link: UrlService.createURL(
      `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${categoryPromoData?.categorySlug}/`
    ),
    categoryPageName: categoryPromoData?.categoryName
  };
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);
  const handleHeaderZoomed = (e: any) => {
    // recalculate when user resumes control/command btn after clicking +, - or =
    if (e.key !== 'Control' && e.key !== 'Meta') {
      return;
    }

    const platform: string = window.navigator.platform;
    const isMac: boolean = platform && !!(platform.toString().match(/^Mac/gi) || platform.toString().match(/^i/gi));
    const isRetinaDisplay = (): boolean => {
      const maybeDefaultPixelRatio = window.devicePixelRatio || 1;

      return isMac && maybeDefaultPixelRatio >= 2;
    };
    const defaultPixelRatio: number = isRetinaDisplay() ? 2 : 1;
    const isZoomed = window.devicePixelRatio && window.devicePixelRatio !== defaultPixelRatio;
    const isMobile = window.innerWidth <= 1024;

    if (isZoomed || isMobile) {
      document.body.setAttribute('__zoom_used', '');
    } else {
      document.body.removeAttribute('__zoom_used');
    }
  };

  useEffect(() => {
    document.body.setAttribute('__new_header', ''); // mark new header for styles
    const adFree = user && activeUserSubscriptions?.length;

    if (adFree) {
      document.body.setAttribute('__adfree', '');
    } else {
      document.body.removeAttribute('__adfree');
    }

    handleHeaderZoomed({ key: 'Control' }); // for case if page is already zoomed on load
    window.addEventListener('keyup', handleHeaderZoomed); // handle zoom on user zoomed on control/command btn
    window.addEventListener('resize', handleHeaderZoomed); // handle zoom on window resize

    return () => {
      document.body.removeAttribute('__new_header');
      document.body.removeAttribute('__adfree');
      window.removeEventListener('keyup', handleHeaderZoomed);
      window.removeEventListener('resize', handleHeaderZoomed);
    };
  }, [user, activeUserSubscriptions]);

  const trackClick = (category: string, index: number) => {
    void Analytics.trackEvent(Analytics.general.topNavClick(category, index));
  };
  const isMenuLinkActive = ({ title, categoryPageName }) => {
    const isHomePage = currentPageType === PageTypes.Home && StringUtils.equalIgnoreCase(title, t('HOME_PAGE'));
    const acceptedCategoryPageTypes: string[] = [PageTypes.AllCategories, PageTypes.Category];
    const isCategoryPage =
      acceptedCategoryPageTypes.includes(currentPageType) && categoryPageName === currentCategoryPageName;

    return isHomePage || isCategoryPage || (currentPageType == PageTypes.BlogArchive && title == 'blog');
  };
  const openLoginSidebar = (signInUpState: SignInUpComponents) => {
    if (signInUpState === SignInUpComponents.SIGN_UP) {
      void Analytics.trackEvent(Analytics.profile.createAccountButton());
    }

    batch(() => {
      dispatch(setSignInUpState(signInUpState));
      dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.HEADER_BUTTON));
    });
  };
  const ProfileOrActions = () => {
    return isUserLoggedIn ? (
      <AvatarGemsCounter/>
    ) : (
      <span className={styles.profileButtons}>
        <button
          className={styles.outline}
          type="button"
          onClick={() => openLoginSidebar(SignInUpComponents.SIGN_IN)}
        >
          <I18nText keyName="SIGN_IN"/>
        </button>
        <button
          className={styles.primary}
          type="button"
          onClick={() => openLoginSidebar(SignInUpComponents.SIGN_UP)}
        >
          <I18nText keyName="CREATE_A_FREE_PROFILE" className={styles.mobileHidden}/>
          <I18nText keyName="CREATE_PROFILE" className={styles.mobileOnly}/>
        </button>
      </span>
    );
  };
  const shouldMenuItemHighlight = (menuItem: { title: string, categoryPageName: string }): boolean => {
    if (pageType === PageTypes.Home && StringUtils.equalIgnoreCase(menuItem.title, t('HOME_PAGE'))) {
      return true;
    }

    return (pageType === PageTypes.AllCategories || pageType === PageTypes.Category) &&
      menuItem.categoryPageName === currentCategoryPageName;


  };
  const getIsUserLoggedIn = () => {
    setIsUserLoggedIn(UserService.isUserLoggedIn());
  };

  useEffect(() => {
    getIsUserLoggedIn();
  }, [user]);

  const HeaderContent = () => {
    const t = props.pageType;

    return (
      <div
        className={classNames({
          [styles.mobileHidden]: t === PageTypes.Game,
          [styles.hidden]: t === PageTypes.Game && hideHeader
        })}
      >
        <header className={styles.header}>
          <a href={UrlService.createURL('/')} aria-label="Go to the homepage">
            <ArkadiumFullLogo
              textHoverColor="#dc1e34"
              arrowHoverColor="#dc1e34"
              focusable={false}
              ariaHidden={true}
              role="presentation"
              className={styles.mobileHidden}
            />
            <ArkadiumIcon className={classNames(styles.mobileOnly, styles.mobileHomeIcon)}/>
          </a>
          <ProfileOrActions/>
        </header>
        <nav className={classNames(styles.subHeader)}>
          <span className={styles.categoryLinks}>
            {menuLinks.map((l) => (
              <a
                key={l.title}
                className={classNames({ [styles.active]: isMenuLinkActive(l) })}
                onClick={() => trackClick(l.categoryPageName, 0)}
                href={l.link}
                data-title={l.title}
                data-testid={l.categoryPageName}
              >
                {l.title}
              </a>
            ))}
            {currentLang === 'en' && categoryPromoData && categoryPromoData?.categoryName?.length > 0 && (
              <a
                key={categoryPromoMenu.title}
                data-testid={categoryPromoMenu.categoryPageName}
                className={classNames(shouldMenuItemHighlight(categoryPromoMenu) && styles.active)}
                onClick={() => trackClick(categoryPromoMenu.categoryPageName, 0)}
                href={categoryPromoMenu.link}
              >
                {categoryPromoMenu.title}
              </a>
            )}
          </span>
          <span className={styles.sidebarButtons}>
            {tabButtons.map((b) => (
              <React.Fragment key={b.keyName}>
                {b.keyName === 'SIDEBAR_BLOG' && <DividerIcon/>}
                <TabButton {...b} />
              </React.Fragment>
            ))}
          </span>
        </nav>
      </div>
    );
  };

  return <HeaderContent/>;
});

type TabButtonProps = {
  onClick: MouseEventHandler<any>;
  icon: JSX.Element;
  keyName: string;
  dataElementDescription: string;
};
const TabButton = React.memo(({ onClick, icon, keyName, dataElementDescription }: TabButtonProps) => {
  return (
    <button type="button" onClick={onClick} data-element-description={dataElementDescription}>
      <div role="presentation">{icon}</div>
      <I18nText as="p" keyName={keyName}/>
    </button>
  );
});

export default Header;
